<template>
  <div>
    <div v-show="privateResource">
      <div class="apply_box_top">
        <div class="apply_box_top_left">
          <div class="apply_box_total">
            <div class="apply_box_total_icon">
              <i class="iconfont icon-hezuoshixin"></i>
            </div>
            <div class="apply_box_total_text apply_box_total_text_special">
              <span>邀约总数(单)</span>
              <span class="apply_box_total_text50">{{inviteNum}}</span>
              <span>/</span>
              <span>{{alreadyLoanNum}}</span>
            </div>
          </div>
        </div>
        <div class="apply_box_top_right">
          <div ref="echarts1" class="echarts_box"></div>
        </div>
      </div>
      <div class="table_common">
        <div class="search_flex_box">
          <div class="search_condition search_condition_right">
            <el-form ref="financingFormss" :model="financingForms">
              <el-form-item label="融资编号:" class="financing_number financing_border" prop="financingNum">
                <el-input v-model="financingForms.financingNum" @input="searchInput" maxlength="20">
                  <template slot="prepend">FN</template>
                </el-input>
              </el-form-item>
              <el-form-item label="融资企业名称:" prop="financingName">
                <el-input v-model="financingForms.financingName"></el-input>
              </el-form-item>
              <el-form-item label="融资企业类型:" prop="financingEnterpriseType">
                <el-select v-model="financingForms.financingEnterpriseType" placeholder="" class="select_class">
                  <el-option v-for="item in  companyState" :key="item.index" :label="item.value" :value="item.codeState"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="融资企业评级:" prop="financingEnterpriseGrade">
                <el-select v-model="financingForms.financingEnterpriseGrade" placeholder="" class="select_class">
                  <el-option v-for="itemGrade in gradeLists1" :key="itemGrade.index" :label="itemGrade.value" :value="itemGrade.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="融资状态:" class="financing_number financing_numbers" prop="financingState">
                <el-select v-model="financingForms.financingState" placeholder="" class="select_class">
                  <el-option v-for="items in financingState1" :key="items.index" :label="items.value" :value="items.codeState"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="申请日期:" class="financing_number" prop="applyDate">
                <el-date-picker v-model="financingForms.applyDate" prefix-icon="iconfont icon-riqi" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                value-format="yyyy-MM-dd" :picker-options="applyDateChoose" :editable="false">
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <div class="search_boxs flex_center_between_box">
            <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
              <div class="search_button_box" @click="searchForm()">
                <i class="iconfont icon-10sousuo"></i>
              </div>
            </el-tooltip>
            <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
              <div class="reset_button_box" @click="resetButton()">
                <i class="iconfont icon-shuaxin"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="table_box">
          <div class="table_box_top">
            <div class="search_button button data_export">数据导出</div>
          </div>
          <div class="table_box_bottom">
            <el-table ref="treeTable" :data="tableData" stripe border style="width: 100%" :row-key="id">
              <el-table-column type="expand" align="center" width="1" class-name="border_change">
                <template slot-scope="scope">
                  <div class="tree_table_box">
                    <el-table :data="treeTableData" stripe border style="width: 100%" :row-key="scope.row.id">
                      <el-table-column label="发票号" prop="invoiceNo" align="center" show-overflow-tooltip :resizable="false" />
                      <el-table-column label="采购商名称" prop="purchaseName" align="left" header-align="center" class-name="financing_cursor" show-overflow-tooltip :resizable="false" :width="nameWidth">
                        <template slot-scope="scope">
                          <div class="link" @click="detailIndex1(scope.row)">{{scope.row.purchaseName}}</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="采购商评级" prop="supplyGrade" align="center" show-overflow-tooltip :resizable="false" :width="width120" />
                      <el-table-column label="发票日期" prop="invoiceDate" align="center" sortable show-overflow-tooltip :resizable="false" :width="width120" />
                      <el-table-column label="发票金额(元)" prop="invoiceAmount" align="right" header-align="center" sortable class-name="align_table" show-overflow-tooltip :resizable="false"
                                       :width="width150s">
                        <template slot-scope="scope">
                          {{scope.row.invoiceAmount | NumFormat}}
                        </template>
                      </el-table-column>
                      <el-table-column label="发票确认状态" prop="isConfirm" align="center" show-overflow-tooltip :resizable="false" :width="width120">
                        <template slot-scope="scope">
                          <div v-if="scope.row.isConfirm == 0">未认可</div>
                          <div v-else>认可</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="发票评级" prop="invoiceGrade" align="center" show-overflow-tooltip :resizable="false">
                        <template slot-scope="scope">
                          <div class="link" @click="clickInvoiceGrade(scope.row.invoiceGrade)">{{scope.row.invoiceGrade}}</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="发票账期(天)" prop="accPeriod" align="center" sortable show-overflow-tooltip :resizable="false" :width="width150s" />
                      <el-table-column label="发票付款状态" prop="invoiceState" align="center" show-overflow-tooltip :resizable="false">
                        <template slot-scope="scope">
                          <div v-if="scope.row.invoiceState == 1">已付款</div>
                          <div v-else>未付款</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="操 作" prop="operation" align="center" :resizable="false">
                        <template slot-scope="scope">
                          <div class="table_oper flex_center">
                            <el-tooltip class="item_color" effect="light" content="详情" placement="top">
                              <i class="iconfont icon-shuxingliebiaoxiangqing" @click="detailsInvoice(scope.row)"></i>
                            </el-tooltip>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <Page :totalFont=true :total=page1.total :currentPage='page1.pageNo' @onPageChange="onPageChangeList"></Page>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="融资编号" prop="financingNum" align="center" :resizable="false" show-overflow-tooltip class-name="financing_cursor" :width="width190">
                <template slot-scope="scope">
                  <span class="expans_icon" :class="{'expandIconExpanded':scope.row.isExpand}" @click="onClickExpansion(scope.row)"><i class="iconfont icon-youjiantou"></i>
                    {{scope.row.financingNum}}</span>
                </template>
              </el-table-column>
              <el-table-column label="融资企业名称" prop="financingName" align="left" header-align="center" show-overflow-tooltip :resizable="false" class-name="financing_cursor" :width="nameWidth">
                <template slot-scope="scope">
                  <div class="link" @click="detailIndex(scope.row)">{{scope.row.financingName}}</div>
                </template>
              </el-table-column>
              <el-table-column label="申请日期" prop="createDate" align="center" sortable show-overflow-tooltip :resizable="false" :width="width120s" />
              <el-table-column label="融资状态" prop="status" align="center" :resizable="false" :width="width135">
                <template slot-scope="scope">
                  <div v-if="scope.row.status== '1'">等待接受邀约</div>
                  <el-badge v-else-if="(scope.row.status == '9') && (scope.row.highly == '1')" is-dot class="item">已被邀约</el-badge>
                  <div v-else-if="scope.row.status == '9'">已被邀约</div>
                  <el-badge v-else-if="(scope.row.status == '2') && (scope.row.highly == '1')" is-dot class="item">等待签约</el-badge>
                  <div v-else-if="scope.row.status== '2'">等待签约</div>
                  <div v-else-if="scope.row.status == '3' ">等待放款</div>
                  <div v-else>等待确认收款</div>
                </template>
              </el-table-column>
              <el-table-column label="融资企业类型" prop="financingEnterpriseType" align="center" show-overflow-tooltip :resizable="false" :width="width120" />
              <el-table-column label="融资企业评级" prop="financingEnterpriseGrade" align="center" show-overflow-tooltip :resizable="false" :width="width120" />
              <el-table-column label="商业评级" prop="commercialCreditRating" align="center" show-overflow-tooltip :resizable="false" :width="width100" />
              <el-table-column label="融资金额(元)" prop="supplierFinancingAmount" align="right" header-align="center" sortable show-overflow-tooltip :resizable="false" :width="width150Money">
                <template slot-scope="scope">
                  {{scope.row.supplierFinancingAmount | NumFormat}}
                </template>
              </el-table-column>
              <el-table-column label="邀约日期" prop="createDate" align="center" :formatter="dateFormat" sortable show-overflow-tooltip :resizable="false" :width="width120s" />
              <el-table-column label="操 作" prop="operation" align="center" :resizable="false" :width="width200" fixed="right">
                <template slot-scope="scope">
                  <div class="table_oper flex_center">
                    <el-tooltip v-if="scope.row.status == '1'" class="item_color_money_complete" effect="light" content="洽谈详情" placement="top">
                      <i class="iconfont icon-picixiangqing"></i>
                    </el-tooltip>
                    <el-tooltip v-else-if="scope.row.status == '9'" class="item_color_money" effect="light" content="洽谈详情" placement="top">
                      <i class="iconfont icon-picixiangqing" @click="negotiateDetail(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip v-else-if="scope.row.status == '2'" class="item_color_money" effect="light" content="合同处理" placement="top">
                      <i class="iconfont icon-hetongdingdan" @click="contractProcessing(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip v-else-if="scope.row.status == '3'" class="item_color_money" effect="light" content="放款" placement="top">
                      <i class="iconfont icon-icon_xinyong_xianxing_jijin-256" @click="loanDialog(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip v-else class="item_color_money" effect="light" content="放款详情" placement="top">
                      <i class="iconfont icon-zhangdan" @click="loanDetails(scope.row)"></i>
                    </el-tooltip>
                    
                    <el-tooltip v-if="scope.row.status != '1' && scope.row.status != '9'&& scope.row.status != '10'&& scope.row.status != '2'" class="item_color_money" effect="light" content="贷款详情"
                                placement="top">
                      <i class="iconfont icon-xiangqingye-35" @click="capitaIlnjection(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip v-if="scope.row.status =='10'" class="item_color_money" effect="light" content="贷款详情" placement="top">
                      <i class="iconfont icon-xiangqingye-35" @click="capitaIlnjections(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip v-if="scope.row.status != '3' && scope.row.status != '10'" class="item_color" effect="light" content="撤销" placement="top">
                      <i class="iconfont icon-chexiao2x" @click="revokeDialog(scope.row,scope.$index)"></i>
                    </el-tooltip>
                    <el-tooltip class="item_color_money" effect="light" content="线下邀约" placement="top">
                      <i class="iconfont icon-miantan" @click="offlineInvitationDialogTrue(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip class="item_color" effect="light" content="服务" placement="top">
                      <i class="iconfont icon--fuwu-xianxing"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Page :totalFont=true :total=page.total :currentPage='page.pageNo' @onPageChange="onPageChange"></Page>
        </div>
      </div>
    </div>
    <!-- 发票评级弹窗 -->
    <el-dialog title="发票评级" :visible.sync="invoiceGradeDialog" center class="dialog_1268" :close-on-click-modal="false">
      <img :src="invoiceAImg" v-if="invoiceImg == 1">
      <img :src="invoiceBImg" v-else-if="invoiceImg == 2">
      <img :src="invoiceCImg" v-else-if="invoiceImg == 3">
      <img :src="invoiceDImg" v-else-if="invoiceImg == 4">
      <img :src="invoiceNaImg" v-else>
    </el-dialog>
    <!-- 放款确认弹框 -->
    <el-dialog title="放款确认" :visible.sync="loanConfirmDialog" center class="dialog_width500 lian_confirm width_1000" :close-on-click-modal="false">
      <div class="waiting_signing">等待放款</div>
      <el-form :model="invPartner" ref="invPartnerRef" :rules="rulesInvPartner">
        <div class="width_1000_left">
          <div class="add_invoice_message inner_common">
            <el-form-item label="融资编号:">
              <el-input v-model="invPartner.financingNum" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业名称:">
              <el-input v-model="invPartner.financingName" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业类型:">
              <el-input v-model="invPartner.financingEnterpriseType" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业评级:">
              <el-input v-model="invPartner.financingEnterpriseGrade" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资发票数量:">
              <el-input v-model="invPartner.invoice_counts" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资金额:">
              <el-input v-model="invPartner.supplierFinancingAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票金额:">
              <el-input v-model="invPartner.invoiceAmount" disabled></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="width_1000_right width_1000_auto">
          <div class="add_invoice_message inner_common">
            <el-form-item label="放款金额:">
              <el-input v-model="invPartner.paymentAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="放款时间:" prop="repaymentDate" :class="exceptArrivalClass ? 'exceptArrivalClass' : ''">
              <el-date-picker type="date" prefix-icon="iconfont icon-riqi" placeholder="" v-model="invPartner.repaymentDate" class="change_picker" value-format="yyyy-MM-dd"
                              @input="exceptArrivalChange1()" :editable="false" :picker-options="repaymentDateChoose"></el-date-picker>
            </el-form-item>
            <el-form-item label="放款银行:" prop="lendingBank">
              <el-input v-model="invPartner.lendingBank" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="放款账号:" prop="lendingNumber">
              <el-input v-model="invPartner.lendingNumber" maxlength="24"></el-input>
            </el-form-item>
            <el-form-item label="银行流水号:" prop="bankSerialNumber">
              <el-input v-model="invPartner.bankSerialNumber" maxlength="30" @input="regNumber1"></el-input>
            </el-form-item>
            <el-form-item label="是否分期:" prop="stages" class="invPartner_item">
              <el-radio-group v-model="invPartner.stages">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上传放款凭证:" class="change_width change_flex" prop="invoiceUrl" ref="uploadFormItem">
              <el-upload ref="uploadForm" :http-request="handleUploadFile" action="fakeaction" v-model="invPartner.invoiceUrl" :on-change="uploadImg" class="upload-demo" list-type="picture"
                         accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG">
                <div class="upload_box">
                  <i class="el-icon-plus"></i>
                </div>
                <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
              </el-upload>
            </el-form-item>
            <div v-show="invPartner.invoiceUrl" class="invoiceUrlClass" :key="indexIndex">
              <ul>
                <li v-for="(item,index) in file" :key="index" class="flex_center" style="position:relative">
                  <img :src="jpg + item">
                  <div class="close_icon" @click="deleteUpload(index)">
                    <i class="iconfont icon-yuyinguanbi"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="loanConfirmDialog = false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="immediateLoanloan()">立即放款</el-button>
      </span>
    </el-dialog>
    <!-- 放款详情弹框 -->
    <el-dialog title="放款详情" :visible.sync="loanDetailsDialog" center class="dialog_width500 lian_confirm width_1000" :close-on-click-modal="false">
      <div class="waiting_signing">等待确认收款</div>
      <el-form :model="invPartner" ref="invPartnerRef" :rules="rulesInvPartner">
        <div class="width_1000_left">
          <div class="add_invoice_message">
            <el-form-item label="融资编号:">
              <el-input v-model="invPartner.financingNum" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业名称:">
              <el-input v-model="invPartner.financingName" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业类型:">
              <el-input v-model="invPartner.financingEnterpriseType" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业评级:">
              <el-input v-model="invPartner.financingEnterpriseGrade" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资发票数量:">
              <el-input v-model="invPartner.financingInvoiceNum" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资金额:">
              <el-input v-model="invPartner.supplierFinancingAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票金额:">
              <el-input v-model="invPartner.invoiceAmount" disabled></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="width_1000_right">
          <div class="add_invoice_message">
            <el-form-item label="放款金额:">
              <el-input v-model="invPartner.paymentAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="放款时间:">
              <el-input v-model="invPartner.actualLendingTime" disabled></el-input>
            </el-form-item>
            <el-form-item label="放款银行:">
              <el-input v-model="invPartner. paymentBank" disabled></el-input>
            </el-form-item>
            <el-form-item label="放款账号:">
              <el-input v-model="invPartner.paymentAccount" disabled></el-input>
            </el-form-item>
            <el-form-item label="银行流水号:">
              <el-input v-model="invPartner.managerBankSerialNumber" disabled></el-input>
            </el-form-item>
            <el-form-item label="放款凭证:" class="label_width_special">
              <ul class="invimg_list flex_wrap">
                <li v-for="(item,index) in invPartner.invImg" :key="index" class="flex_center">
                  <img :src="jpg + item">
                </li>
              </ul>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="loanDetailsDialog = false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="loanDetailsDialog = false">确 认</el-button>
      </span>
    </el-dialog>
    <!-- 撤销弹框 -->
    <el-dialog title="撤销融资" :visible.sync="revokeFinancingDialog" center class="dialog_width500" :close-on-click-modal="false">
      <div class="revoke_confirm_box">
        是否撤销此条融资信息？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="revokeFinancingDialog = false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="confirmDialog()">确 认</el-button>
      </span>
    </el-dialog>
    <!-- 贷款详情弹框 -->
    <el-dialog title="贷款详情" :visible.sync="financingDetailsDialog" center class="dialog_width600 dialog_bottom600" :close-on-click-modal="false">
      <div class="message_compare message_special flex_center_between_box message_forms">
        <span>我的融资信息</span>
        <span>资方融资条件</span>
      </div>
      <div class="message_form message_detail">
        <el-form ref="financingForm" :model="financingForm1">
          <el-form-item label="放款天数:">
            <el-input v-model="financingForm1.supplyRepaymentDay" disabled></el-input>
            <i class="iconfont icon-youjiantou"></i>
            <el-input v-model="financingForm1.managerRepaymentDay" disabled></el-input>
            <span class="data_compare_exhibition">
              <i class="iconfont icon-web-icon-1" v-if="item === -1"></i>
              <i class="iconfont icon-web-icon-" v-else-if="item === 1"></i>
              <i v-else></i>
              <span v-if="item === -1" class="compare_color1">{{compareDateRise}}天</span>
              <span v-else-if="item === 1" class="compare_color2">{{compareDateRise}}天</span>
              <span v-else class="compare_color1">{{compareDateRise}}天</span>
            </span>
          </el-form-item>
          <el-form-item label="还款日期:">
            <el-input v-model="financingForm1.supplierRepaymentDate" disabled></el-input>
            <i class="iconfont icon-youjiantou"></i>
            <el-input v-model="financingForm1.managementRepaymentDate" disabled></el-input>
            <span class="data_compare_exhibition">
              <i class="iconfont icon-web-icon-1" v-if="item1 === -1"></i>
              <i class="iconfont icon-web-icon-" v-else-if="item1 === 1"></i>
              <i v-else></i>
              <span v-if="item1 === -1" class="compare_color1">{{compareDateRise1}}天</span>
              <span v-else-if="item1 === 1" class="compare_color2">{{compareDateRise1}}天</span>
              <span v-else class="compare_color1">{{compareDateRise1}}天</span>
            </span>
          </el-form-item>
          <el-form-item label="融资金额:">
            <el-input v-model="financingForm1.supplierFinancingAmount" disabled></el-input>
            <i class="iconfont icon-youjiantou"></i>
            <el-input v-model="financingForm1.managementFinancingAmount" disabled></el-input>
            <span class="data_compare_exhibition">
              <i class="iconfont icon-web-icon-1" v-if="item2 === -1"></i>
              <i class="iconfont icon-web-icon-" v-else-if="item2 === 1"></i>
              <i v-else></i>
              <span v-if="item2 === -1" class="compare_color1">{{compareDateRise2}}</span>
              <span v-else-if="item2 === 1" class="compare_color2">{{compareDateRise2}}</span>
              <span v-else class="compare_color1">{{compareDateRise2}}</span>
            </span>
          </el-form-item>
          <el-form-item label="融资利率:">
            <el-input v-model="financingForm1.supplierRate" disabled></el-input>
            <i class="iconfont icon-youjiantou"></i>
            <el-input v-model="financingForm1.managementRate" disabled></el-input>
            <span class="data_compare_exhibition">
              <i class="iconfont icon-web-icon-1" v-if="item3 === -1"></i>
              <i class="iconfont icon-web-icon-" v-else-if="item3 === 1"></i>
              <i v-else></i>
              <span v-if="item3 === -1" class="compare_color1">{{compareDateRise3}}%</span>
              <span v-else-if="item3 === 1" class="compare_color2">{{compareDateRise3}}%</span>
              <span v-else class="compare_color1">{{compareDateRise3}}%</span>
            </span>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="financingDetailsDialog = false;getDate()">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 等待确认收款的贷款详情 -->
    <el-dialog title="贷款详情" :visible.sync="investmentDetailsDialog" center class="dialog_width500 dialog_add_edit_form width_1100" :close-on-click-modal="false">
      <div class="width_1100_left">
        <div class="add_invoice_message inner_common notXin">
          <el-form :model="financingFormsD" refs="financingFormsD">
            <el-form-item label="融资编号:">
              <el-input v-model="financingFormsD.financingNum" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业名称:">
              <el-input v-model="financingFormsD.financingName" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业类型:">
              <el-input v-model="financingFormsD.financingEnterpriseType" disabled></el-input>
            </el-form-item>
            <el-form-item label="投资金额:">
              <el-input v-model="financingFormsD.managementFinancingAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资利率:">
              <el-input v-model="financingFormsD.managementRate" disabled></el-input>
            </el-form-item>
            <el-form-item label="放款时间:">
              <el-input v-model="financingFormsD.actualLendingTime" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款时间:">
              <el-input v-model="financingFormsD.managementRepaymentDate" disabled></el-input>
            </el-form-item>
            <el-form-item label="账款状态:">
              <el-input v-model="financingFormsD.state" disabled></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="width_1100_right width_1000_auto">
        <div class="add_invoice_message inner_common notXin">
          <div class="table_tit">申请融资发票详情:</div>
          <el-table class="dialog_table" :data="tableData0" stripe border style="width: 100%">
            <el-table-column label="发票编号" prop="invoiceNo" align="center" :resizable="false" show-overflow-tooltip :width="dialogidWidth" />
            <el-table-column label="发票日期" prop="invoiceDate" align="center" show-overflow-tooltip :resizable="false" />
            <el-table-column label="发票金额" prop="invoiceAmount" align="right" header-align="center" show-overflow-tooltip :resizable="false">
              <template slot-scope="scope">
                {{scope.row.invoiceAmount | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column label="发票账期" prop="accPeriod" align="center" show-overflow-tooltip :resizable="false" />
            <el-table-column label="账款到期日" prop="invoiceDateEnd" align="center" header-align="center" show-overflow-tooltip :resizable="false" :width="dialogWidth" />
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="investmentDetailsDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 选择合同文件弹框 -->
    <el-dialog title="选择合同文件" :visible.sync="chooseContractTemplateDialog" center class="add add_class dialog_width500" :close-on-click-modal="false" :before-close="handleClose">
      <div class="waiting_signing">等待签约</div>
      <div class="add_invoice_content">
        <div class="search_invoice">
          <el-input v-model="product.contractName" placeholder="搜索合同文件">
            <i slot="suffix" class="el-input__icon iconfont icon-10sousuo" @click="contractProcessing()"></i>
          </el-input>
        </div>
        <div class="choose_invoice">
          <div class="choose_invoice_title">我的文件:</div>
          <div class="over_flow_box">
            <div class="choose_invoice_list">
              <ul :key="activeIndex">
                <el-tooltip :content="item.formworkName" v-for="(item,index) in purchaserList" :key="index" placement="top" effect="light" popper-class="choose_tooltip">
                  <li class="choose_invoice_button over" @click="changeStyle(item,index)" ref="choose" :class="[item.listChoose ? 'choose_active_change' : '']">
                    {{item.formworkName}}
                  </li>
                </el-tooltip>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="footer_button1" @click="next()">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 合同处理弹框 -->
    <el-dialog title="合同处理" :visible.sync="contractProcessingDialog" center class=" dialog_width500 left_right_20" :close-on-click-modal="false" :before-close="handleClose">
      <div class="waiting_signing">等待签约</div>
      <div class="management_box" v-show="noBlock">
        <div class="management_box_title flex_center_between_box">
          <div>供应商合同材料:</div>
          <el-button class="download_button flex_center" @click="loadingFile()">
            <i class="iconfont icon-xiazai"></i>
            <span>下载全部材料</span>
          </el-button>
        </div>
        <ul class="ul">
          <li class="flex_center_box" v-for="(item,index) in upLoadFileName.supplier" :key="index">
            <div class=" contract_download_box" v-if="item.type === '.docx'">
              <img :src="compressImg" alt="">
            </div>
            <div class=" contract_download_box" v-else-if="(item.type).substring((item.type).length-5) == '.xlsx'">
              <i class="iconfont icon-Microsoft-Excel"></i>
            </div>
            <div class=" contract_download_box" v-else>
              <img :src="compressImgPdf" alt="">
            </div>
            <span class="file_name">{{item.name}}</span>
          </li>
        </ul>
      </div>
      <div class="management_box" v-show="!noBlock">
        <div class="management_box_title flex_center_between_box">
          <div>供应商合同材料:</div>
          <el-button class="download_button flex_center" :disabled="true">
            <i class="iconfont icon-xiazai"></i>
            <span>下载全部合同</span>
          </el-button>
        </div>
        <div class="management_box_title flex_center_between_box">
          <div>供应商未上传合同材料</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2 button_class1" round @click="contractRejection()" :disabled='!noBlock'>合同驳回</el-button>
        <el-button class="footer_button1 button_class1" type="primary" :disabled='!noBlock' @click="comfireButton()">确 认</el-button>
      </span>
    </el-dialog>
    <!-- 洽谈详情弹框 -->
    <el-dialog title="洽谈详情" :visible.sync="negotiateDetailDialog" class="dialog_width600 negotiate_detail" center :close-on-click-modal="false" :before-close="handleClose">
      <div class="public_box">
        <div class="public_title">放贷条件</div>
        <div class="message_compare message_special flex_center_between_box negotiate_special">
          <span>供应商融资条件</span>
          <span>投资方融资条件</span>
        </div>
        <div class="message_form message_detail">
          <el-form ref="financingForm" :model="financingForm1">
            <el-form-item label="放款天数:">
              <el-input v-model="financingForm1.supplyRepaymentDay" disabled></el-input>
              <i class="iconfont icon-youjiantou"></i>
              <el-input v-model="financingForm1.managerRepaymentDay" disabled :picker-options="applyDateChoose"></el-input>
              <span class="data_compare_exhibition">
                <i class="iconfont icon-web-icon-1" v-if="item === -1"></i>
                <i class="iconfont icon-web-icon-" v-else-if="item === 1"></i>
                <i v-else></i>
                <span v-if="item === -1" class="compare_color1">{{compareDateRise}}天</span>
                <span v-else-if="item === 1" class="compare_color2">{{compareDateRise}}天</span>
                <span v-else class="compare_color1">{{compareDateRise}}天</span>
              </span>
            </el-form-item>
            <el-form-item label="还款时间:">
              <el-input v-model="financingForm1.supplierRepaymentDate" disabled></el-input>
              <i class="iconfont icon-youjiantou"></i>
              <el-input v-model="financingForm1.managementRepaymentDate" disabled></el-input>
              <span class="data_compare_exhibition">
                <i class="iconfont icon-web-icon-1" v-if="item1 === -1"></i>
                <i class="iconfont icon-web-icon-" v-else-if="item1 === 1"></i>
                <i v-else></i>
                <span v-if="item1 === -1" class="compare_color1">{{compareDateRise1}}天</span>
                <span v-else-if="item1 === 1" class="compare_color2">{{compareDateRise1}}天</span>
                <span v-else class="compare_color1">{{compareDateRise1}}天</span>
              </span>
            </el-form-item>
            <el-form-item label="融资金额:">
              <el-input v-model="financingForm1.supplierFinancingAmount" disabled></el-input>
              <i class="iconfont icon-youjiantou"></i>
              <el-input v-model="financingForm1.managementFinancingAmount" disabled></el-input>
              <span class="data_compare_exhibition">
                <i class="iconfont icon-web-icon-1" v-if="item2 === -1"></i>
                <i class="iconfont icon-web-icon-" v-else-if="item2 === 1"></i>
                <i v-else></i>
                <span v-if="item2 === -1" class="compare_color1">{{compareDateRise2}}</span>
                <span v-else-if="item2 === 1" class="compare_color2">{{compareDateRise2}}</span>
                <span v-else class="compare_color1">{{compareDateRise2}}</span>
              </span>
            </el-form-item>
            <el-form-item label="融资利率:" prop="managementRate">
              <el-input v-model="financingForm1.supplierRate" disabled></el-input>
              <i class="iconfont icon-youjiantou"></i>
              <el-input v-model="financingForm1.managementRate" disabled></el-input>
              <span class="data_compare_exhibition">
                <i class="iconfont icon-web-icon-1" v-if="item3 === -1"></i>
                <i class="iconfont icon-web-icon-" v-else-if="item3 === 1"></i>
                <i v-else></i>
                <span v-if="item3 === -1" class="compare_color1">{{compareDateRise3}}%</span>
                <span v-else-if="item3 === 1" class="compare_color2">{{compareDateRise3}}%</span>
                <span v-else class="compare_color1">{{compareDateRise3}}%</span>
              </span>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="base_message_box1">
        <div class="public_title title_special">我的反馈</div>
        <el-form :model="baseForm" class="form_message" ref="baseForm" :rules="rulesForm1">
          <el-form-item label="期望到款天数:" prop="paymentTime">
            <el-input v-model.number="baseForm.paymentTime" :picker-options="applyDateChoose" maxlength="3" @input="codeInput" @change="regTime()"></el-input>
            <!-- <el-date-picker
                        v-model="baseForm.paymentTime"
                        prefix-icon="iconfont icon-riqi"
                        type="date"
                        @input="exceptArrivalChange()"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions1">
                        </el-date-picker> -->
          </el-form-item>
          <el-form-item label="期望还款时间:" prop="repaymentTime" :class="exceptRepaymentClass ? 'exceptRepaymentClass' : ''">
            <el-date-picker v-model="baseForm.repaymentTime" :editable="false" prefix-icon="iconfont icon-riqi" type="date" @input="regTime()" value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions2">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="期望融资金额:" prop="financingMoney">
            <el-input v-model.number="baseForm.financingMoney" maxlength="8" @focus="inputMoneyFocusfin(baseForm.financingMoney)" @blur="inputMoneyBlurfin(baseForm.financingMoney)" @input="codeInput1"
                      @change="regTime()"></el-input>
          </el-form-item>
          <el-form-item label="期望融资利率:" prop="financingRate">
            <el-input v-model="baseForm.financingRate" maxlength="3" @input="codeInput2" @change="regTime()"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1 button_change1" @click="agreeFeedback(1)" :class="[backgroundColor1?'bg_button2':'bg_button1']">同意签约</el-button>
        <el-button class="footer_button1 button_change" type="primary" @click="agreeFeedback(2)" :class="[(backgroundColor&&backgroundColorTime)?'bg_button1':'bg_button2']">反馈提交</el-button>
      </span>
    </el-dialog>
    <!-- 发票详情 -->
    <el-dialog title="发票详情" :visible.sync="invoiceDetailDialog" center class="dialog_width500 dialog_add_edit_form width_1000" :close-on-click-modal="false">
      <el-form :model="invoiceForm" refs="invoiceForm">
        <div class="add_invoice_message inner_common notXin">
          <div class="width_1000_left">
            <el-form-item label="采购商名称:">
              <el-input v-model="invoiceForm.purchaseName" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票号码:">
              <el-input v-model="invoiceForm.invoiceNo" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票金额:">
              <el-input v-model="invoiceForm.invoiceAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票日期:">
              <el-input v-model="invoiceForm.invoiceDate" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票账期:">
              <el-input v-model="invoiceForm.accPeriod" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票确认状态:">
              <el-input v-model="invoiceForm.isConfirm" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票付款状态:">
              <el-input v-model="invoiceForm.invoiceState" disabled></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="width_1000_right">
          <div class="add_invoice_message inner_common notXin">
            <el-form-item label="付款金额:">
              <el-input v-model="invoiceForm.paymentAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票付款日期:">
              <el-input v-model="invoiceForm.paymentDate" disabled></el-input>
            </el-form-item>
            <el-form-item label="采购商付款凭证:" prop="invImg" class="label_width_special">
              <ul class="invimg_list flex_wrap" v-show="invimgDisplay">
                <li class="flex_center">
                  <img :src="jpg + invoiceForm.paymentImg">
                </li>
              </ul>
            </el-form-item>
            <el-form-item label="买家是否已收货:" class="label_width_special special_disabled">
              <el-input v-model="invoiceForm.isReceived" disabled></el-input>
            </el-form-item>
            <el-form-item label="买家是否已收发票:" class="label_width_special special_disabled">
              <el-input v-model="invoiceForm.isInvoice" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票图片:" prop="invImg" class="label_width_special">
              <ul class="invimg_list flex_wrap">
                <li class="flex_center">
                  <img :src="jpg + invoiceForm.invImg">
                </li>
              </ul>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="invoiceDetailDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 线下邀约 -->
    <el-dialog title="线下邀约" :visible.sync="offlineInvitationDialog" center class="dialog_width500 off_special" :close-on-click-modal="false">
      <div class="add_invoice_messages inner_common ">
        <el-form :model="offlineInvitation" ref="offlineInvitation" :rules="offlineInvitationRule">
          <el-form-item label="被邀约对象:" prop="invitedName">
            <el-input v-model="offlineInvitation.invitedName" disabled></el-input>
          </el-form-item>
          <el-form-item label="邀约方式:" prop="invitationMethod">
            <el-select v-model="offlineInvitation.invitationMethod" placeholder="" class="select_class">
              <el-option v-for="item in offlineInvitationSelect" :key="item.codeState" :label="item.value" :value="item.codeState">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="邀约时间:" prop="invitationTime" :class="exceptRepaymentClass ? 'exceptRepaymentClass' : ''">
            <el-date-picker v-model="offlineInvitation.invitationTime" prefix-icon="iconfont icon-riqi" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="invitationTimePicker"
                            :editable="false" @change="exceptArrivalChange2()">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="邀约地点:" prop="invitationAddress" v-if="offlineInvitation.invitationMethod === '3'">
            <el-input v-model="offlineInvitation.invitationAddress"></el-input>
          </el-form-item>
          <el-form-item label="邀约内容:" v-if="offlineInvitation.invitationMethod === '3'">
            <el-input type="textarea" maxlength="200" :autosize="{ minRows: 3, maxRows: 3}" show-word-limit v-model="offlineInvitation.invitationContent">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="offlineInvitationDialog = false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="sumbitofflineInvitation()">提 交</el-button>
      </span>
    </el-dialog>
    <div v-show="detailDisplay">
      <!-- <div class="second_return_icon" @click="returnButton()">
                <i class="iconfont icon-fanhui" ></i>
                融资企业详情
            </div> -->
      <Company ref="Company"></Company>
    </div>
  </div>
</template>

<script>
import { mixins1 } from "@/mixins/index";
import Page from '@/components/page'
import Company from '../../supplier/components/companyDetail.vue'
import {
  findPriCount, getPubPriSeaList, getFinancingCompList, findCodeInfoByKey, updateManagePubPriSeaFlag, updateFinancingComparisonInfo,
  updateFinancingComparisonStatue, contract, sureContract, findInvoiceInfoByFinancingNum, loan, findContractConfigList, editContractConfig, uploads,
  downloadContractSupplier, getContractSupplier, noContract, getInvoiceUrl, getManageInvestmentInfoById
} from '@/api/investor.js'
import { getInvoiceList, offlineInvitation } from '@/api/supplier.js'
import moment from "moment"
export default {
  mixins: [mixins1],
  components: {
    Page,
    Company,
  },
  computed:{
    repaymentDateChoose(){
      let self = this
      return{
        disabledDate(time){
          console.log(self.repayEnd)
          console.log(self.repayStart)
          return time.getTime() > new Date(self.repayEnd).getTime() ||  new Date(self.repayStart).getTime()- 8.64e7>time.getTime();
        }
      }
    }
  },
  data() {
    const valManagementRate = (rule, val, cb) => {
      if (val == '') {
        return cb()
      } else if (val === 0 || val === '0') {
        return cb(new Error('请输入大于0的数字'))
      }
      else if ((val < 0) || (val > 100)) {
        return cb(new Error('请输入0-100之间数字'))
      }
      return cb()
    }
    const valInvoiceAmount = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入放款金额'))
      }
      else if ((Number(val) > Number(this.filterOut(this.invPartner.supplierFinancingAmount))) && val) {
        return cb(new Error('放款金额不能大于发票金额'))
      }
      return cb()
    }
    const valRepaymentDate = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请选择放款时间'))
      }
      return cb()
    }
    const valLendingBank = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入放款银行'))
      } else if (/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(val) == false) {
        return cb(new Error("请输入20位中英文或数字"));
      }
      return cb()
    }
    const valLendingNumber = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入放款账号'))
      } else if (/^[0-9]*$/.test(val) == false) {
        return cb(new Error("请输入24位数字"));
      }
      return cb()
    }
    const valBankSerialNumber = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入银行流水号'))
      } else if (/^[0-9]*$/.test(val) == false) {
        return cb(new Error("请输入30位数字"));
      }
      return cb()
    }
    const valInvoiceUrl = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请上传放款凭证'))
      }
      return cb()
    }
    const valiedateprecon = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入被邀约对象'))
      }
      return cb()
    }
    const valiedateon = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入邀约方式'))
      }
      return cb()
    }
    const valiedateTime = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入邀约时间'))
      }
      return cb()
    }
    const stagesRules = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请选择是否分期'))
      }
      return cb()
    }
    const valiedated = (rule, val, cb) => {
      if (!val && (this.offlineInvitation.invitationMethod == '3')) {
        return cb(new Error('请输入邀约地点'))
      }
      return cb()
    }
    const valFinancingMoney = (rule, val, cb) => {
      if (val === 0 || val === '0') {
        return cb(new Error('期望融资金额不能为0'))
      }
      return cb()
    }
    
    return {
      repayStart:null,
      repayEnd:null,
      indexIndex: '1',
      upLoadFileName: {},
      activeIndex: 0,
      uploads: {
        uploadUrl: ''
      },
      rulesForm1: {
        financingRate: [
          { validator: valManagementRate, trigger: 'blur' }
        ],
        financingMoney:[
        { validator: valFinancingMoney, trigger: 'blur' }
        ]
      },
      rulesInvPartner: {
        loanAmount: [{ required: true, validator: valInvoiceAmount, trigger: 'blur' }],
        repaymentDate: [{ required: true, validator: valRepaymentDate, trigger: 'blur' }],
        lendingBank: [{ required: true, validator: valLendingBank, trigger: 'blur' }],
        lendingNumber: [{ required: true, validator: valLendingNumber, trigger: 'blur' }],
        bankSerialNumber: [{ required: true, validator: valBankSerialNumber, trigger: 'blur' }],
        invoiceUrl: [{ required: true, validator: valInvoiceUrl, trigger: 'blur' }],
        stages: [{ required: true, validator: stagesRules, trigger: 'change' }],
      },
      fileList: [
      ],
      compressImg: require('@/assets/img/system/supplier/word.png'),
      compressImgPdf: require('@/assets/img/pdf.png'),
      invoiceAImg: require('@/assets/img/system/invoice_A.png'),
      invoiceBImg: require('@/assets/img/system/invoice_B.png'),
      invoiceCImg: require('@/assets/img/system/invoice_C.png'),
      invoiceDImg: require('@/assets/img/system/invoice_D.png'),
      invoiceNaImg: require('@/assets/img/system/invoice_NA.png'),
      gradeLists: [],
      companyState: [],
      gradeLists1: [],
      financingState1: [],
      list1: [
        {
          value: 0,
          name: '已签约单数'
        },
        {
          value: 0,
          name: '未签约单数'
        },
      ],
      financingForms: {
        financingNum: '',
        financingName: '',
        financingEnterpriseType: '',
        financingEnterpriseGrade: '',
        applyDate: '',
        pageNo: 1,
        pageSize: 8,
        startTime: '',
        endTime: '',
        financingState: '',
      },
      financingForm: {
        supplierRepaymentDate: '',
        managementRepaymentDate: '',
        supplierArrivalDate: '',
        managementArrivalDate: '',
        supplierFinancingAmount: '',
        managementFinancingAmount: '',
        supplierRate: '',
        managementRate: '',
      },
      financingForm1: {
        supplyRepaymentDay: '',
        managerRepaymentDay: '',
        supplierRepaymentDate: '',
        managementRepaymentDate: '',
        supplierFinancingAmount: '',
        managementFinancingAmount: '',
        supplierRate: '',
        managementRate: '',
      },
      financingFormsD: {
        financingNum: "",
        financingName: "",
        financingEnterpriseType: "",
        managementFinancingAmount: "",
        financingRate: "",
        lendTime: "",
        repaymentTime: "",
        state: ''
      },
      id: '',
      tableData: [
      ],
      tableData0: [],
      treeTableData: [],
      invPartner: {
        financingNum: '',
        companyName: '',
        financingEnterpriseType: '',
        financingEnterpriseGrade: '',
        financingInvoiceNum: '',
        supplierFinancingAmount: '',
        invoiceAmount: '',
        paymentAmount: '',
        repaymentDate: '',
        lendingBank: '',
        lendingNumber: '',
        bankSerialNumber: '',
        supplierId: '',
        invoiceUrl: '',
        invImg: [{ img: '' }],
        managerId: '',
        id: '',
        stages: ''
      },
      invoiceForm: {
        purchaseName: '',
        invoiceNo: '',
        invoiceAmount: '',
        invoiceDate: '',
        accPeriod: '',
        isConfirm: '',
        invoiceState: '',
        paymentAmount: '',
        paymentDate: '',
        paymentImg: [{ img: '' }],
        isReceived: '',
        isInvoice: '',
        invImg: [{ img: '' }]
      },
      product: {
        contractName: '',
      },
      purchaserList: [
        // {
        //     name: '合同文件1'
        // },
        // {
        //     name: '合同文件2'
        // },
        // {
        //     name: '合同文件3'
        // },
      ],
      uploadform: {
        file: '',
        // rejectionReason:''
      },
      baseForm: {
        // companyName:'中国银行股份有限公司',
        // accountManager:'',
        // contactInformation:'',
        // loanTime1:'2021/10/04',
        // loanTime2:'',
        // repaymentTime1:'2021/10/04',
        // repaymentTime2:'',
        // financingMoney1:'100元',
        // financingMoney2:'',
        // loan1:'10%',
        // loan2:'',
        paymentTime: '',
        repaymentTime: '',
        financingMoney: '',
        financingRate: ''
      },
      contractList: [
        {
          name: '文件名1'
        },
        {
          name: '文件名2'
        },
        {
          name: '文件名3'
        },
      ],
      state: -1,
      compareDateRise: '',
      compareDateRise1: '',
      compareDateRise2: '',
      compareDateRise3: '',
      compareDateDecline: '',
      compareDateEqual: '0',
      loanConfirmDialog: false,
      revokeFinancingDialog: false,
      invoiceDetailDialog: false,
      financingDetailsDialog: false,
      compareRise: true,
      compareDecline: false,
      chooseContractTemplateDialog: false,
      contractProcessingDialog: false,
      contractRejectionDialog: false,
      negotiateDetailDialog: false,
      ulDisplay: true,
      index: [],
      item: true,
      item1: true,
      item2: -1,
      item3: -1,
      backgroundColor: false,
      backgroundColorTime: true,
      backgroundColor1: true,
      exceptArrivalClass: false,
      exceptRepaymentClass: false,
      inviteNum: 0,
      alreadyLoanNum: 0,
      indexList: [],
      noBlock: true,
      privateResource: true,
      detailDisplay: false,
      detailDisplay1: false,
      detailDisplay2: false,
      detailDisplay3: false,
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      page1: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      revoke: {
      },
      contractData: {
        manageId: '',
        financingNum: '',
        contractNum: ''
      },
      sureContractData: {
        id: '',
        supplierId: '',
        financingNum: ''
      },
      uploadId: '',
      filesListForm: {
        file: '',
        contract_name: ''
      },
      listId: [],
      fileButton: false,
      confirmSupplierId: '',
      onClickExpansionFinancingNum: '',
      onClickExpansionIndex: '',
      onClickExpansionIndexkey: 1,
      pickerOptions1: {
        disabledDate: (time) => {
          if (this.baseForm.repaymentTime == '') {
            return (
              time.getTime() < Date.now()
            )
          }
          if (this.baseForm.repaymentTime != '') {
            return (
              time.getTime() > new Date(this.baseForm.repaymentTime).getTime() - 30 * 24 * 3600 * 1000 || time.getTime() < Date.now()
            )
          }
        }
      },
      pickerOptions2: {
        disabledDate: (time) => {
          if (this.baseForm.paymentTime != '') {
            return (
              time.getTime() < Date.now() + (this.baseForm.paymentTime) * 24 * 3600 * 1000 + 30 * 24 * 3600 * 1000
            )
          } else {
            return time.getTime() < Date.now() + 30 * 24 * 3600 * 1000
          }
        }
      },
      invitationTimePicker: {
        disabledDate: (time) => {
          if (this.offlineInvitation.invitationTime == '') {
            return (
              time.getTime() < Date.now()
            )
          } else {
            return (
              time.getTime() < Date.now()
            )
          }
        }
      },
      invoiceNumss: '',
      fapiaomoney: '',
      upLoadFileNumber: '',
      invimgDisplay: true,
      hasFmt: true,
      loanDetailsDialog: false,
      investmentDetailsDialog: false,
      dialogidWidth: 85,
      dialogWidth: 100,
      file: [],
      offlineInvitationSelect: [],
      offlineInvitationRule: {
        invitedName: [
          { required: true, validator: valiedateprecon, trigger: 'blur' }
        ],
        invitationMethod: [
          { required: true, validator: valiedateon, trigger: 'blur' }
        ],
        invitationTime: [
          { required: true, validator: valiedateTime, trigger: 'blur' }
        ],
        invitationAddress: [
          { required: true, validator: valiedated, trigger: 'blur' }
        ],
      },
      offlineInvitation: {
        invitationName: '',
        invitedName: '',
        invitationMethod: '0',
        invitationTime: '',
        invitationAddress: '',
        invitedId: ''
      },
      offlineInvitationDialog: false,
      invoiceGradeDialog: false,
      invoiceImg: ''
    }
  },
  created() {
    this.resizeFn();
    this.tableWidth()
    this.totalComputed()
    this.getDate()
    this.ficationList()
    this.ficationList1()
    this.ficationList2()
  },
  methods: {
    searchInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForms.financingNum = val
    },
    // 发票评级提示
    clickInvoiceGrade(val) {
      this.invoiceGradeDialog = true
      if (val == 'A') {
        this.invoiceImg = 1
      } else if (val == 'B') {
        this.invoiceImg = 2
      } else if (val == 'C') {
        this.invoiceImg = 3
      } else if (val == 'D') {
        this.invoiceImg = 4
      } else {
        this.invoiceImg = 5
      }
    },
    regNumber1(val) {
      val = val.replace(/[^\d]/g, '')
      this.invPartner.bankSerialNumber = val
    },
    inputMoneyFocusfin(value) {
      this.baseForm.financingMoney = this.filterOut(value)
    },
    inputMoneyBlurfin(value) {
      this.baseForm.financingMoney = this.filter(value)
    },
    regTime() {
      console.log(this.backgroundColorTime)
      if (this.baseForm.paymentTime || this.baseForm.repaymentTime || this.baseForm.financingMoney || this.baseForm.financingRate) {
        this.backgroundColor = true
      } else {
        this.backgroundColor = false
      }
    },
    codeInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.baseForm.paymentTime = val
    },
    codeInput1(val) {
      val = val.replace(/[^\d]/g, '')
      this.baseForm.financingMoney = val
    },
    codeInput2(val) {
      val = val.replace(/[^\d]/g, '')
      this.baseForm.financingRate = val
    },
    uploadImg(file) {
      this.$nextTick(() => {
        this.$refs['uploadFormItem'].clearValidate()
      })
    },
    handleUploadFile(params) {
      const file = params.file
      let form = new FormData()
      form.append("files", file)
      uploads(form).then(res => {
        this.invPartner.invoiceUrl = res[0]
        this.indexIndex++
        this.file.push(this.invPartner.invoiceUrl)
      })
    },
    deleteUpload(index) {
      this.file.splice(index, 1)
    },
    dateFormat(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD");
    },
    async getDate() {
      if (this.financingForms.applyDate != null) {
        if (this.financingForms.applyDate[0]) {
          this.financingForms.startTime = this.financingForms.applyDate.slice(',')[0]
          this.financingForms.endTime = this.financingForms.applyDate.slice(',')[1]
        } else {
          this.financingForms.startTime = ''
          this.financingForms.endTime = ''
        }
      } else {
        this.financingForms.startTime = ''
        this.financingForms.endTime = ''
      }
      let res = await getPubPriSeaList('pageNo=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&financingNum=' + this.financingForms.financingNum + '&financingName=' + this.financingForms.financingName +
        '&financingEnterpriseType=' + this.financingForms.financingEnterpriseType +
        '&financingEnterpriseGrade=' + this.financingForms.financingEnterpriseGrade
        + '&startTime=' + this.financingForms.startTime + '&endTime=' + this.financingForms.endTime
        + '&financingState=' + this.financingForms.financingState + '&ms=' + 2)
      this.tableData = res.data.records
      this.page.total = res.data.total
      res = await findPriCount()
      this.list1[0].value = res.data.amount_lent
      this.list1[1].value = res.data.outstanding_loans
      this.inviteNum = res.data.total_invitation_num
      this.alreadyLoanNum = res.data.amount_lent
      this.$nextTick(function () {
        this.riskEchart2(
          this.fontSize,
          this.echartsWidth
        )
      })
    },
    async getChildernList(item) {
      let res = await getInvoiceList('financingNum=' + item)
      this.treeTableData = res.data.records
      this.page1.total = res.data.total
    },
    async onPageChangeList(item) {
      this.page1.pageNo = item
      let res = await getInvoiceList('pageNo=' + this.page1.pageNo + '&pageSize=' + this.page1.pageSize
        + '&financingNum=' + this.onClickExpansionFinancingNum)
      this.treeTableData = res.data.records
    },
    async negotiateList(item) {
      let res = await getFinancingCompList('financingNum=' + item)
      this.financingForm1 = res.data.list[0]
      this.financingForm1.supplierRate = this.financingForm1.supplierRate + '%'
      this.financingForm1.managementRate = this.financingForm1.managementRate + '%'
      console.log(res)
      this.invoiceNumss = res.data.utilsCount.sum_invoice_amount
      if (res.data.list[0].timeRatio > 0) {
        this.item = 1
      }
      else if (res.data.list[0].timeRatio < 0) {
        this.item = -1
      }
      else {
        this.item = 0
      }
      if (res.data.list[0].paymentTimeRatio > 0) {
        this.item1 = 1
      }
      else if (res.data.list[0].paymentTimeRatio < 0) {
        this.item1 = -1
      }
      else {
        this.item1 = 0
      }
      if (res.data.list[0].rateRatio > 0) {
        this.item3 = 1
      }
      else if (res.data.list[0].rateRatio < 0) {
        this.item3 = -1
      }
      else {
        this.item3 = 0
      }
      if (res.data.list[0].amountRatio > 0) {
        this.item2 = 1
      }
      else if (res.data.list[0].amountRatio < 0) {
        this.item2 = -1
      }
      else {
        this.item2 = 0
      }
      this.compareDateRise = Math.abs(res.data.list[0].timeRatio)
      this.compareDateRise1 = Math.abs(res.data.list[0].paymentTimeRatio)
      this.compareDateRise2 = Math.abs(res.data.list[0].amountRatio)
      this.compareDateRise3 = Math.abs(res.data.list[0].rateRatio)
      this.financingForm1.supplierFinancingAmount = this.filter(this.financingForm1.supplierFinancingAmount)
      this.financingForm1.managementFinancingAmount = this.filter(this.financingForm1.managementFinancingAmount)
      // this.compareDateRise2 = this.filter(this.compareDateRise2)
      if (res.data.list[0].button == '1') {
        this.backgroundColor1 = false
      } else {
        this.backgroundColor1 = true
      }
      this.fapiaomoney = res.data.utilsCount.sum_invoice_amount
    },
    async ficationList1() {
      let res = await findCodeInfoByKey('ms=' + 28)
      this.companyState = res.data
      res = await findCodeInfoByKey('ms=17')
      this.offlineInvitationSelect = res.data
    },
    async ficationList() {
      let res = await findCodeInfoByKey('ms=' + 9)
      this.gradeLists1 = res.data
    },
    async ficationList2() {
      let res = await findCodeInfoByKey('ms=' + 13)
      this.financingState1 = res.data
    },
    searchForm() {
      this.page.pageNo = 1
      this.page1.pageNo = 1
      this.getDate()
    },
    resetButton() {
      this.$refs.financingFormss.resetFields()
      this.page.pageNo = 1
      this.page1.pageNo = 1
      this.getDate()
    },
    exceptArrivalChange() {
      if (this.baseForm.repaymentTime) {
        this.exceptRepaymentClass = true
      } else {
        this.exceptRepaymentClass = false
      }
      if ((this.baseForm.financingRate > 100) && (this.baseForm.financingRate)) {
        this.backgroundColorTime = false
      }
      else {
        this.backgroundColorTime = true
      }
      if (this.baseForm.paymentTime || this.baseForm.repaymentTime || this.baseForm.financingMoney || (this.baseForm.financingRate <= 100 && this.baseForm.financingRate > 0)) {
        this.backgroundColor = true
      }
      else {
        this.backgroundColor = false
      }
    },
    exceptArrivalChange1() {
      if (this.invPartner.repaymentDate) {
        this.exceptArrivalClass = true
      }
      else {
        this.exceptArrivalClass = false
      }
    },
    exceptArrivalChange2() {
      if (this.offlineInvitation.invitationTime) {
        this.exceptRepaymentClass = true
      }
      else {
        this.exceptRepaymentClass = false
      }
    },
    // 下载合同
    loadingFile() {
      // window.location.href="/file/合同.docx"
      // var link = document.createElement("a"); 
      // link.setAttribute("download", "");
      // link.href = `${process.env.BASE_URL}file/beian_import.xls`;
      // link.click();
      // link.remove();
      console.log(this.upLoadFileNumber)
      this.upLoadFileNumber.forEach((item, index) => {
        console.log(item, index)
        var link = document.createElement("a");
        console.log(link)
        link.setAttribute("download", this.upLoadFileName.supplier[index]?.name?this.upLoadFileName.supplier[index].name:this.upLoadFileName.supplier[index]);
        // link.href = item;
        link.href = this.jpg + item;
        link.target="_blank"
        link.click();
        // console.log(this.upLoadFileName[index].name)
      })
    },
    // echarts图标参数
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.fontSize = 14
        this.echartsWidth = 36
        this.width200 = 200
        this.width160 = 153
        this.width150s = 150
        this.width150Money = 136
        this.width145 = 145
        this.width135 = 118
        this.width120s = 115
        this.width100 = 85
      } else {
        this.fontSize = 10
        this.echartsWidth = 24
        this.width200 = 134
        this.width160 = 101
        this.width150s = 120
        this.width150Money = 120
        this.width145 = 115
        this.width135 = 95
        this.width120s = 90
        this.width100 = 70
      }
      this.$nextTick(function () {
        this.riskEchart2(
          this.fontSize,
          this.echartsWidth
        )
      })
    },
    // 展开子表格
    onClickExpansion(row) {
      this.onClickExpansionFinancingNum = row.financingNum
      this.tableData.map((item) => {
        if (row.id != item.id) {
          item.isExpand = false;
          this.$refs.treeTable.toggleRowExpansion(item, false);
        }
      })
      row.isExpand = !row.isExpand;
      this.$refs.treeTable.toggleRowExpansion(row)
      if (row.isExpand) {
        this.getChildernList(row.financingNum)
      }
    },
    // 放款
    async loanDialog(row) {
      
      let res = await findInvoiceInfoByFinancingNum('financingNum=' + row.financingNum)
      this.loanConfirmDialog = true
      
      this.invPartner = JSON.parse(JSON.stringify(row))
      this.invPartner.invoice_counts = res.data.invoice_counts
      this.invPartner.invoiceAmount = res.data.sum_invoice_amount
      this.invPartner.supplierFinancingAmount = this.filter(this.invPartner.supplierFinancingAmount)
      this.invPartner.managementFinancingAmount = this.filter(this.invPartner.managementFinancingAmount)
      this.invPartner.invoiceAmount = this.filter(this.invPartner.invoiceAmount)
      this.invPartner.supplierId = row.userCode
      this.invPartner.managerId = row.managementId
      this.invPartner.id = row.id
      this.invPartner.paymentAmount = this.invPartner.managementFinancingAmount
      this.repayStart = row.actualLendingTime
      this.repayEnd = row.managementRepaymentDate
      console.log( this.repayStart)
      console.log( this.repayEnd)
      this.file = []
      this.$refs.invPartnerRef.resetFields()
    },
    // 撤销
    async revokeDialog(row, index) {
      this.index.push(index)
      this.revokeFinancingDialog = true
      this.revoke.id = row.id
      this.revoke.financingNum = row.financingNum
      this.revoke.userCode = row.userCode
      this.revoke.managerId = row.managementId
    },
    // 详情
    detailsDialog() {
      this.publicResource = false
      this.detailDisplay = true
      window.scrollTo(0, 0)
    },
    // 返回上一页
    returnButton() {
      this.detailDisplay = false
      this.privateResource = true
      this.detailDisplay1 = false
      this.detailDisplay2 = false
      this.detailDisplay3 = false
      window.scrollTo(0, 0)
    },
    // 发票详情
    detailsInvoice(row) {
      // let res = await getInvoiceUrl('invoiceNo='+row.invoiceNo)
      this.invoiceDetailDialog = true
      // this.invoiceForm.invImg[0].img = res.data
      // let list=this.invoiceForm.invImg
      this.invoiceForm = row
      this.invoiceForm.paymentImg = row.paymentVoucher
      this.invoiceForm.invImg = row.invoiceUrl
      // this.invoiceForm.invImg=list
      this.invoiceForm.invoiceAmount = this.filter(this.invoiceForm.invoiceAmount)
      if (row.invoiceState == '0') {
        this.invoiceForm.invoiceState = '未付款'
        this.invimgDisplay = false
      } else if (row.invoiceState == '1') {
        this.invoiceForm.invoiceState = '已付款'
        this.invimgDisplay = true
      }
      if (row.isConfirm == '0') {
        this.invoiceForm.isConfirm = '未认可'
      } else if (row.isConfirm == '1') {
        this.invoiceForm.isConfirm = '认可'
      }
      if (row.isReceived == '0') {
        this.invoiceForm.isReceived = '否'
      } else if (row.isReceived == '1') {
        this.invoiceForm.isReceived = '是'
      }
      if (row.isInvoice == '0') {
        this.invoiceForm.isInvoice = '否'
      } else if (row.isInvoice == '1') {
        this.invoiceForm.isInvoice = '是'
      }
    },
    // 列表点击样式
    changeStyle(item, index) {
      item.listChoose = !item.listChoose
      this.activeIndex++;
      if (item.listChoose) {
        this.listId.push(item.id)
      }
      else {
        this.listId.forEach((i, index) => {
          if (i === item.id) {
            this.listId.splice(index, 1)
          }
        })
      }
    },
    // 提交
    async next() {
      if (this.listId.length > 0) {
        let res = await contract('financingNum=' + this.contractData.financingNum + '&manageId=' + this.contractData.manageId + '&contractNum=' + this.contractData.contractNum + '&id=' + this.listId)
        this.chooseContractTemplateDialog = false
        this.getDate()
      }
    },
    handleClose() {
      this.product.contractName = ''
      this.chooseContractTemplateDialog = false
      this.contractProcessingDialog = false
      this.contractRejectionDialog = false
      this.negotiateDetailDialog = false
      this.getDate()
    },
    // 洽谈详情
    negotiateDetail(row) {
      this.negotiateDetailDialog = true
      this.$nextTick(function () {
        this.$refs.baseForm.resetFields();
      })
      this.negotiateList(row.financingNum)
      this.backgroundColor = false
      this.confirmSupplierId = row.userCode
    },
    // 贷款详情
    capitaIlnjection(row) {
      this.financingDetailsDialog = true
      this.negotiateList(row.financingNum)
    },
    // 确认撤销
    async confirmDialog() {
      this.revokeFinancingDialog = false
      let res = await updateManagePubPriSeaFlag('id=' + this.revoke.id + '&financingNum=' + this.revoke.financingNum + '&supplierId=' + this.revoke.userCode + '&managerId=' + this.revoke.managerId)
      this.getDate()
    },
    // 反馈提交和同意签约
    async agreeFeedback(index) {
      if ((this.baseForm.paymentTime && !this.baseForm.repaymentTime) || (!this.baseForm.paymentTime && this.baseForm.repaymentTime)) {
        this.$message({
          message: "期望到款天数和期望还款时间需同时填写!",
          type: 'warning'
        })
      } else if (this.baseForm.financingMoney && (parseInt(this.filterOut(this.baseForm.financingMoney)) > parseInt(this.fapiaomoney))) {
        this.$message({
          message: '您的融资金额不能高于发票总金额!',
          type: 'warning'
        });
      } else if ((this.baseForm.paymentTime < 8) && (this.baseForm.paymentTime)) {
        this.$message({
          message: '期望到款天数需大于7天!',
          type: 'warning'
        });
      } else {
        if ((index == 1 && this.backgroundColor1 == false)) {
          let res = await updateFinancingComparisonStatue('financingNum=' + this.financingForm1.financingNum + '&supplierId=' + this.confirmSupplierId)
          this.negotiateDetailDialog = false
          this.getDate()
        }
        else if (index == 2 && this.backgroundColor == true) {
          this.$refs.baseForm.validate(async (valid) => {
            if (valid) {
              let params = {}
              params.id = this.financingForm1.id
              params.financingNum = this.financingForm1.financingNum
              if (!this.baseForm.paymentTime) {
                params.managementArrivalDate = this.financingForm1.supplyRepaymentDay
              } else {
                params.managementArrivalDate = this.baseForm.paymentTime
              }
              if (!this.baseForm.repaymentTime) {
                params.managementRepaymentDate = this.financingForm1.supplierRepaymentDate
              } else {
                params.managementRepaymentDate = this.baseForm.repaymentTime
              }
              if (!this.baseForm.financingMoney) {
                this.financingForm1.supplierFinancingAmount = this.filterOut(this.financingForm1.supplierFinancingAmount)
                params.managementFinancingAmount = this.financingForm1.supplierFinancingAmount
              } else {
                this.baseForm.financingMoney = this.filterOut(this.baseForm.financingMoney)
                params.managementFinancingAmount = this.baseForm.financingMoney
              }
              if (!this.baseForm.financingRate) {
                params.managementRate = this.financingForm1.supplierRate.split('%')[0]
              } else {
                params.managementRate = this.baseForm.financingRate
              }
              params.supplierArrivalDate = this.financingForm1.supplyRepaymentDay
              params.supplierRepaymentDate = this.financingForm1.supplierRepaymentDate
              this.financingForm1.supplierFinancingAmount = this.filterOut(this.financingForm1.supplierFinancingAmount)
              params.supplierFinancingAmount = this.financingForm1.supplierFinancingAmount
              params.supplierRate = this.financingForm1.supplierRate.split('%')[0]
              params.managerId = this.financingForm1.userCode
              let res = await updateFinancingComparisonInfo(params)
              this.negotiateDetailDialog = false
              this.getDate()
            } else {
              return false;
            }
          })

        }
      }
    },
    // 合同处理
    async contractProcessing(row) {
      if (row.contractNum == '1') {
        this.contractProcessingDialog = true
        this.chooseContractTemplateDialog = false
        this.noBlock = false
      } else if (row.contractNum == '2') {
        this.contractProcessingDialog = true
        this.noBlock = true
        let res = await getContractSupplier('financing_num=' + row.financingNum + '&manager_id=' + row.managementId + '&supplier_id=' + row.userCode)
        this.upLoadFileName = res.data
      } else {
        this.chooseContractTemplateDialog = true
      }
      this.listId = []
      let res = await findContractConfigList('pageNo=' + -1 + '&contractName=' + this.product.contractName)
      this.purchaserList = res.data.records
      this.purchaserList.forEach((item, index) => {
        item.listChoose = false
      });
      this.indexList.length = 0
      this.contractData.manageId = row.userCode
      this.contractData.financingNum = row.financingNum
      this.contractData.contractNum = row.contractNum
      this.sureContractData.id = row.id
      this.sureContractData.supplierId = row.userCode
      this.sureContractData.financingNum = row.financingNum
      this.sureContractData.manageId = row.managementId
      res = await downloadContractSupplier('financing_num=' + this.sureContractData.financingNum + '&manager_id=' + this.sureContractData.manageId + '&supplier_id=' + this.sureContractData.supplierId)
      this.upLoadFileNumber = res.data
      this.uploadId = row.id
    },
    // 合同驳回
    async contractRejection() {
      let res = await noContract('financingNum=' + this.contractData.financingNum + '&supplierId=' + this.contractData.manageId)
      this.contractProcessingDialog = false
      this.chooseContractTemplateDialog = true
      this.$refs.product.resetFields();
    },
    // 计算
    totalComputed() {
      this.inviteNum = this.list1[0].value + this.list1[1].value
      this.alreadyLoanNum = this.list1[0].value
    },
    detailIndex(row) {
      this.$refs.Company.getCompany(row.financingName)
      this.privateResource = false
      this.detailDisplay = true
      // this.getDate()
    },
    detailIndex1(row) {
      this.$refs.Company.getCompany(row.purchaseName)
      this.privateResource = false
      this.detailDisplay = true
      // this.getDate()
    },
    async comfireButton() {
      let res = await sureContract('supplierId=' + this.sureContractData.supplierId + '&financingNum=' + this.sureContractData.financingNum)
      this.contractProcessingDialog = false
      this.chooseContractTemplateDialog = false
      this.getDate()
    },
    async immediate() {
      let form = {}
      form.supplierId = this.invPartner.supplierId
      form.managerId = this.invPartner.managerId
      form.id = this.invPartner.id
      form.financingNum = this.invPartner.financingNum
      // form.paymentAmount = this.invPartner.loanAmount
      form.actualLendingTime = this.invPartner.repaymentDate
      form.paymentAmount = this.filterOut(this.invPartner.paymentAmount)
      form.paymentBank = this.invPartner.lendingBank
      form.paymentAccount = this.invPartner.lendingNumber
      form.managerBankSerialNumber = this.invPartner.bankSerialNumber
      form.managerRepaymentVoucher = this.file
      form.isStage = this.invPartner.stages
      let res = await loan(form)
      this.getDate()
    },
    // 立即放款
    immediateLoanloan() {
      this.$refs.invPartnerRef.validate((valid) => {
        if (this.file.length > 0) {
          this.$refs['uploadFormItem'].clearValidate()
          if (valid) {
            this.immediate()
            this.loanConfirmDialog = false
          } else {
            return false;
          }
        }
      })

    },
    async confirmDetail(item1, item2) {
      let res = await getFinancingCompList('financingNum=' + item1 + '&manageId=' + item2)
      this.invPartner = res.data.list[0]
      this.invPartner.invoiceAmount = res.data.utilsCount.sum_invoice_amount
      this.invPartner.managementFinancingAmount = this.filter(this.invPartner.managementFinancingAmount)
      // this.invPartner.invoiceAmount = this.filter(this.invPartner.invoiceAmount)
      this.invPartner.paymentAmount = this.filter(this.invPartner.paymentAmount)
      this.financingFormsD = res.data.list[0]
      this.invPartner.supplierFinancingAmount = this.filter(this.invPartner.supplierFinancingAmount)
      this.financingFormsD.managementFinancingAmount = this.filter(this.financingFormsD.managementFinancingAmount)
      if (this.financingFormsD.state == '0') {
        this.financingFormsD.state = '未确认'
      }
      else if (this.financingFormsD.state == '1') {
        this.financingFormsD.state = '已确认'
      }
      this.invPartner.invImg = res.data.list[0].paymentVouchers
      // console.log(this.invPartner.invImg[0].img)
    },
    //等待确认收款的放款详情
    loanDetails(row) {
      this.loanDetailsDialog = true
      this.confirmDetail(row.financingNum, row.managementId)
    },
    async invoiceTableList(item) {
      let res = await getManageInvestmentInfoById('Id=' + item)
      this.tableData0 = res.data.invoiceEntities
    },
    // 等待确认收款的贷款详情
    capitaIlnjections(row) {
      this.investmentDetailsDialog = true
      this.confirmDetail(row.financingNum, row.managementId)
      this.invoiceTableList(row.id)
    },
    //线下邀约
    offlineInvitationDialogTrue(row) {
      this.$nextTick(() =>
        this.$refs.offlineInvitation.resetFields()
      )
      this.offlineInvitationDialog = true,
        this.offlineInvitation.invitedName = row.financingName
      this.offlineInvitation.invitationId = row.managementId,
        this.offlineInvitation.invitationName = row.manageName,
        this.offlineInvitation.invitedId = row.userCode,
        this.FN = row.financingNum
    },
    //提交
    sumbitofflineInvitation() {
      this.$refs.offlineInvitation.validate(async (valid) => {
        console.log(valid)
        if (valid) {
          if (
            (this.baseForm.paymentTime && !this.baseForm.repaymentTime)
            || (!this.baseForm.paymentTime && this.baseForm.repaymentTime)) {
            this.backgroundColorTime = false
            this.$message({
              message: "放款天数和还款日期需同时填写!",
              type: 'warning'
            })
          }
          else if (this.baseForm.financingMoney && (parseInt(this.filterOut(this.baseForm.financingMoney)) > parseInt(this.fapiaomoney))) {
            this.backgroundColorTime = false
            this.$message({
              message: '您的融资金额不能高于发票总金额!',
              type: 'warning'
            });
          }
          else {
            let data = await offlineInvitation(this.FN, this.offlineInvitation)
            this.offlineInvitationDialog = false
            this.getDate()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>


<style scoped>
  ::v-deep .invPartner_item .el-form-item__content {
    line-height: 41px;
  }
  ::v-deep .el-table th > .cell {
    z-index: 1;
  }
  .off_special >>> .el-form-item__label {
    min-width: 83px !important;
  }
  ::v-deep.add_invoice_messages .el-input__prefix {
    left: 92%;
  }
  .add_invoice_message li {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 12px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  /* .add_invoice_message ul{
                                                                                                                                  margin-top: 10px;
                                                                                                                              } */
  /* ::v-deep .is-success .change_picker.el-date-editor--date:hover .el-input__prefix{
                                                                                                                                  display: none;
                                                                                                                              }
                                                                                                                              ::v-deep .change_picker.el-date-editor--date:hover .el-input__prefix{
                                                                                                                                  display: block;
                                                                                                                              } */
  .table_tit {
    line-height: 40px;
    font-size: 14px;
    color: #162747;
  }
  .change_width >>> .el-upload-list {
    width: 357px;
  }

  .change_width >>> .el-upload__tip {
    right: 40px;
  }
  ::v-deep .el-upload-list--picture .el-upload-list__item {
    display: none;
  }
  .invoiceUrlClass {
    margin-left: 98px;
    margin-top: -10px;
  }
  .close_icon {
    position: absolute;
    top: -6px;
    left: 52px;
    display:flex;
    align-items:center;
    justify-content:center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fb6476;
  }
  .close_icon i {
    /* position: relative;
    top: -3px;
    left: 2px; */
    font-size: 8px;
    color: #ffffff;
  }
  .el-upload__tip {
    right: 58px;
  }
  ::v-deep .el-badge {
    color: #fb6476;
  }
  ::v-deep .el-badge__content.is-fixed {
    top: 6px;
  }
  ::v-deep .el-badge__content.is-fixed.is-dot {
    right: -1px;
  }
  ::v-deep .el-badge__content {
    background-color: #fb6476;
  }
  .financing_numbers >>> .el-input__inner {
    padding-left: 15px !important;
  }
  .add_class >>> .el-dialog--center .el-dialog__body {
    padding-right: 25px !important;
  }
  ::v-deep .el-button--primary.is-disabled {
    background-color: #dae4e9;
    border-color: #dae4e9;
  }
  .is-disabled.button_class1 {
    background: #dae4e9;
    border: 1px solid #dae4e9;
    cursor: not-allowed;
    color: #ffffff;
  }
  ::v-deep .left_right_20 .el-dialog--center .el-dialog__body {
    padding: 0 15px 0 20px;
  }

  .icon-Microsoft-Excel {
    font-size: 30px;
    color: #107b0f;
  }
  .management_box {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    background: #f7fafc;
    border-radius: 10px;
  }
  .management_box ul {
    /* height: 264px; */
    overflow: auto;
  }
  .management_box li {
    margin-bottom: 10px;
  }
  .management_box li:last-child {
    margin-bottom: 0;
  }
  .management_box_title {
    height: 60px;
  }
  .management_box_title div {
    height: 40px;
  }
  .file_name {
    width: 80%;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contract_download_box {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
    margin-right: 14px;
    padding-left: 15px;
    padding-top: 15px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .contract_download_box img {
    width: 30px;
    height: 30px;
  }
  .product_top {
    margin-top: 10px;
  }
  .form_message >>> .el-form-item__content,
  .form_message >>> .el-input {
    width: 402px !important;
  }
  .lian_confirm >>> .el-form-item__label {
    min-width: 98px !important;
  }
  .inner_common >>> .el-form {
    padding-left: 0 !important;
  }
  ::v-deep .exceptArrivalClass .el-date-editor--date:hover .el-input__prefix {
    display: none;
  }
  ::v-deep .exceptRepaymentClass .el-date-editor--date:hover .el-input__prefix {
    display: none;
  }
  ::v-deep
    .exceptArrivalClass
    .is-success
    .el-date-editor--date:hover
    .el-input__prefix {
    display: none;
  }
  ::v-deep
    .exceptRepaymentClass
    .is-success
    .el-date-editor--date:hover
    .el-input__prefix {
    display: none;
  }
  .all {
    overflow: hidden;
  }
  .apply_box_top_left {
    width: 340px;
    height: 176px;
    margin-right: 60px;
    padding: 20px;
    background: #339c9b;
    box-shadow: 0px 10px 20px 0px rgba(47, 160, 159, 0.23);
    border-radius: 14px;
  }
  .apply_box_total {
    display: flex;
    align-items: center;
    width: 300px;
    height: 136px;
    padding-top: 20px;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 20px;
    background: rgba(237, 242, 245, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
    border-radius: 14px;
  }
  .echarts_box {
    height: 250px;
    margin-top: -40px;
  }
  .table_box_top {
    float: right;
  }
  .search_condition {
    flex: 1;
  }
  .search_condition_right >>> .el-form-item {
    margin-right: 28px;
  }
  .search_flex_box >>> .el-form-item {
    margin-bottom: 20px;
  }
  .search_flex_box >>> .el-form-item:last-child {
    margin-bottom: 0;
  }
  .search_flex_box >>> .el-form-item:nth-child(3) {
    margin-right: 0;
  }
  .table_oper .item_color_money_complete {
    color: #e1e7ea;
    cursor: not-allowed;
  }
  .invimg_list li {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 12px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .invimg_list img:hover {
    position: absolute;
    bottom: -1px;
    left: 0px;
    cursor: pointer;
    transform: scale(1);
  }
  .message_compare {
    margin-bottom: 10px;
  }
  .choose_active {
    color: #162747;
    background: #ffffff;
  }
  .choose_active_change {
    color: #ffffff;
    background: #339c9b;
  }
  .upload-demo {
    width: 60px;
    height: 60px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .add_class >>> .el-upload {
    position: relative;
    left: 20px;
  }
  .add_class >>> .el-form-item__content {
    font-size: 20px;
    color: #7c969b;
  }
  .add_class >>> .el-upload__tip {
    right: 106px;
    margin-top: 15px;
  }
  .upload_box {
    width: 60px;
    height: 60px;
    margin-right: 6px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .upload_box i {
    font-size: 20px;
    line-height: 60px;
    color: #7c969b;
  }
  .upload_box i:focus {
    color: #7c969b;
  }
  .add_class >>> .el-upload-list {
    width: 280px;
    margin-top: 0;
  }
  ::v-deep .el-upload-list--picture .el-upload-list__item {
    /* float: left; */
    height: 62px;
    margin-bottom: 20px;
  }
  .change_flex >>> .el-form-item__content {
    float: none;
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    color: #92a2bc;
  }
  .change_flex span {
    height: 26px;
  }
  .choose_invoice_list li {
    width: 126px;
    margin-right: 10px;
  }
  .dialog_width500 >>> .el-form-item__label {
    min-width: 126px;
  }
  .dialog_add_edit_form >>> .el-form-item__label {
    min-width: 98px;
  }
  .contract_download_box {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-top: 15px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .contract_download_box img {
    width: 30px;
    height: 30px;
  }
  .icon-yasuobao {
    font-size: 30px;
    position: relative;
    left: 14px;
    top: 15px;
  }
  .is-disabled.download_button,
  .download_button.is-disabled.el-button--default:hover {
    color: #e1e7ea;
  }
  .download_button {
    width: 160px;
    height: 40px;
    margin-top: -20px;
    color: #339c9b;
    background: #ffffff;
    border-radius: 32px;
    border: 1px solid #e1e7ea;
  }
  .download_button.el-button--default:hover,
  .download_button.el-button--default:focus {
    color: #339c9b;
  }
  .download_button span {
    font-size: 16px;
  }
  .download_button i {
    margin-right: 6px;
    font-size: 18px;
  }
  .lending_conditions_box {
    height: 304px;
    margin-bottom: 20px;
  }
  .lending_conditions_box >>> .el-form-item__label {
    width: 95px;
  }
  .lending_conditions_box >>> .el-input {
    width: 150px;
  }
  .lending_conditions_box i {
    margin-left: 17px;
    margin-right: 17px;
    font-size: 14px;
    color: #7c969b;
  }
  .lending_conditions_box >>> .el-icon-date {
    position: relative;
    left: 115px;
  }
  .public_box {
    width: 100%;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 10px;
    background: #f7fafc;
  }
  .public_title {
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 14px;
    color: #162747;
  }
  .message_detail >>> .el-form-item:last-child {
    margin-bottom: 0;
  }
  .base_message_box {
    height: 244px;
    margin-bottom: 10px;
  }
  .base_message_box1 {
    height: 294px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: #f7fafc;
  }
  .base_message_box1 >>> .el-input__prefix .el-icon-date {
    position: absolute;
    left: 372px;
  }
  .base_message_box >>> .el-form-item__content {
    float: left;
    width: 372px;
  }
  .negotiate_special {
    padding-right: 152px !important;
  }
  .title_special {
    margin-left: 0;
  }
  .bg_button1 {
    background: #339c9b;
    border: 1px solid #339c9b;
  }
  .bg_button2 {
    background: #dae4e9;
    border: 1px solid #dae4e9;
    cursor: not-allowed;
  }
  /* 合同处理 */
  .contract_processing_box {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .contract_processing_box span:first-child {
    position: relative;
    left: 30px;
  }
  .contract_processing_box span:last-child {
    position: relative;
    left: 280px;
  }
  .contract_processing_ul li {
    width: 100%;
    height: 116px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 50px;
    background: #f7fafc;
    border-radius: 10px;
  }
  .contract_processing_ul li:last-child {
    margin-bottom: 20px;
  }
  .contract_processing_ul_title {
    margin-bottom: 10px;
  }
  .flex_first {
    flex: 1;
    margin-right: 80px;
  }
  .contract_download_box_special {
    margin-right: 20px;
  }
  .download_button_special {
    margin-top: -15px;
  }
  .upload_demo_box {
    position: relative;
    margin-top: -20px;
  }
  .contract_processing_ul .upload_box {
    margin-left: 20px;
  }
  .contract_processing_ul >>> .el-upload-list__item {
    position: absolute;
    top: -13px;
    left: -1px;
  }
  .over_flow_box {
    overflow: auto;
  }
  /* .add_invoice_content{
                                                                                                                                  height: 260px !important;
                                                                                                                              } */
  .over_flow_box >>> .el-form-item {
    margin-bottom: 0 !important;
  }
  .financing_number >>> .el-form-item__label {
    min-width: 98px !important;
  }
  .download_button_specials {
    margin-top: 0 !important;
  }
  @media screen and (max-width: 1664px) {
    ::v-deep .invPartner_item .el-form-item__content {
      line-height: 26px;
    }
    .change_width >>> .el-upload__tip {
      right: -32px;
    }
    .add_invoice_message li {
      float: left;
      width: 48px;
      height: 48px;
      margin-right: 10px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .invoiceUrlClass {
      margin-left: 83px;
      margin-top: -6px;
    }
    .close_icon {
      position: absolute;
      top: -4px;
      left: 41px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fb6476;
      transform: scale(0.9);
    }
    .close_icon i {
      position: relative;
      top: -4px;
      left: 0px;
      font-size: 2px;
      color: #ffffff;
    }
    .off_special >>> .el-form-item__label {
      min-width: 71px !important;
    }
    .table_tit {
      line-height: 27px;
      font-size: 12px;
      color: #162747;
    }
    .el-upload__tip {
      right: -26px;
    }
    ::v-deep .el-badge__content.is-fixed {
      top: 4px;
    }
    ::v-deep .el-badge__content.is-fixed.is-dot {
      right: -1px;
    }
    .add_class >>> .el-dialog--center .el-dialog__body {
      padding-right: 15px !important;
    }
    ::v-deep .left_right_20 .el-dialog--center .el-dialog__body {
      padding: 0 10px 0 13px;
    }
    .icon-Microsoft-Excel {
      font-size: 20px;
      color: #107b0f;
    }
    .management_box {
      width: 100%;
      height: auto;
      margin-bottom: 6px;
      padding-top: 13px;
      padding-left: 20px;
      padding-right: 20px;
      background: #f7fafc;
      border-radius: 6px;
    }
    .management_box ul {
      /* height: 176px; */
      overflow: auto;
    }
    .management_box li {
      margin-bottom: 6px;
    }
    .management_box_title {
      height: 40px;
    }
    .management_box_title div {
      height: 26px;
      font-size: 12px;
    }
    .file_name {
      width: 80%;
      margin-bottom: 6px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .contract_download_box {
      width: 40px;
      height: 40px;
      margin-bottom: 13px;
      margin-right: 9px;
      padding-left: 10px;
      padding-top: 10px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .contract_download_box img {
      width: 20px;
      height: 20px;
    }
    .product_top {
      margin-top: 6px;
    }
    .form_message >>> .el-form-item__content,
    .form_message >>> .el-input {
      width: 247px !important;
    }
    .lian_confirm >>> .el-form-item__label {
      min-width: 83px !important;
    }
    .apply_box_top_left {
      width: 226px;
      height: 117px;
      margin-right: 40px;
      padding: 13px;
      background: #339c9b;
      box-shadow: 0px 6px 13px 0px rgba(47, 160, 159, 0.23);
      border-radius: 10px;
    }
    .apply_box_total {
      display: flex;
      align-items: center;
      width: 200px;
      height: 90px;
      padding-top: 13px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 13px;
      background: rgba(237, 242, 245, 0.2);
      box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
      border-radius: 10px;
    }
    .echarts_box {
      height: 166px;
      margin-top: -26px;
    }
    .search_condition_right >>> .el-form-item {
      margin-right: 4px;
    }
    .search_flex_box >>> .el-form-item {
      margin-bottom: 13px;
    }
    .invimg_list li {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .invimg_list img:hover {
      position: absolute;
      bottom: -1px;
      left: 0px;
      cursor: pointer;
      transform: scale(1);
    }
    .message_compare {
      margin-bottom: 6px;
    }
    .upload-demo {
      width: 40px;
      height: 40px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .add_class >>> .el-upload {
      position: relative;
      left: 13px;
    }
    .add_class >>> .el-form-item__content {
      font-size: 14px;
      color: #7c969b;
    }
    .add_class >>> .el-upload__tip {
      right: 22px;
      margin-top: 10px;
      transform: scale(0.7);
    }
    .upload_box {
      width: 40px;
      height: 40px;
      margin-right: 4px;
      background: #f7fafc;
      border-radius: 2px;
      border: 1px solid #e1e7ea;
    }
    .upload_box i {
      font-size: 14px;
      line-height: 40px;
      color: #7c969b;
    }
    .add_class >>> .el-upload-list {
      width: 160px;
      margin-top: 0;
    }
    ::v-deep .el-upload-list--picture .el-upload-list__item {
      height: 42px;
      margin-bottom: 13px;
    }
    .change_flex >>> .el-form-item__content {
      float: none;
      display: flex;
      align-items: flex-end;
      font-size: 12px;
      color: #92a2bc;
    }
    .change_flex span {
      height: 17px;
    }
    .choose_invoice_list li {
      width: 84px;
      margin-right: 5px;
    }
    .dialog_width500 >>> .el-form-item__label {
      min-width: 84px;
    }
    .contract_download_box {
      width: 40px;
      height: 40px;
      margin-bottom: 13px;
      padding-left: 10px;
      padding-top: 10px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .contract_download_box img {
      width: 20px;
      height: 20px;
    }
    .dialog_add_edit_form >>> .el-form-item__label {
      min-width: 82px;
    }
    .icon-yasuobao {
      font-size: 20px;
      position: relative;
      left: 9px;
      top: 10px;
    }
    .download_button {
      width: 106px;
      height: 26px;
      margin-top: -13px;
      color: #339c9b;
      background: #ffffff;
      border-radius: 22px;
      border: 1px solid #e1e7ea;
    }
    .download_button span {
      font-size: 12px;
    }
    .download_button i {
      margin-right: 4px;
      font-size: 12px;
    }
    .lending_conditions_box {
      height: 202px;
      margin-bottom: 13px;
    }
    .lending_conditions_box >>> .el-form-item__label {
      width: 63px;
    }
    .lending_conditions_box >>> .el-input {
      width: 100px;
    }
    .lending_conditions_box i {
      margin-left: 11px;
      margin-right: 11px;
      font-size: 12px;
      color: #7c969b;
    }
    .lending_conditions_box >>> .el-icon-date {
      position: relative;
      left: 76px;
    }
    .public_box {
      width: 100%;
      margin-bottom: 6px;
      padding-top: 6px;
      padding-bottom: 13px;
      padding-left: 6px;
      background: #f7fafc;
    }
    .public_title {
      margin-bottom: 13px;
      margin-left: 6px;
      font-size: 12px;
      color: #162747;
    }
    .title_special {
      margin-left: 0;
    }
    .message_detail >>> .el-form-item:last-child {
      margin-bottom: 0;
    }
    .message_forms {
      padding-right: 76px !important;
    }
    .base_message_box {
      height: 162px;
      margin-bottom: 6px;
    }
    .base_message_box1 {
      height: 193px;
      margin-bottom: 6px;
      padding-top: 6px;
      padding-left: 13px;
      padding-right: 13px;
      background: #f7fafc;
    }
    .base_message_box1 >>> .el-input__prefix .el-icon-date {
      position: absolute;
      left: 217px;
    }
    .base_message_box1 >>> .el-form-item__label {
      min-width: 82px !important;
    }
    .base_message_box >>> .el-form-item__content {
      float: left;
      width: 248px;
    }
    .negotiate_special {
      padding-right: 90px !important;
    }
    /* 合同处理 */
    .contract_processing_box {
      margin-top: 10px;
      margin-bottom: 6px;
    }
    .contract_processing_box span:first-child {
      position: relative;
      left: 20px;
    }
    .contract_processing_box span:last-child {
      position: relative;
      left: 186px;
    }
    .contract_processing_ul li {
      width: 100%;
      height: 77px;
      margin-bottom: 6px;
      padding-top: 6px;
      padding-bottom: 13px;
      padding-left: 20px;
      padding-right: 33px;
      background: #f7fafc;
      border-radius: 6px;
    }
    .contract_processing_ul li:last-child {
      margin-bottom: 13px;
    }
    .contract_processing_ul_title {
      margin-bottom: 6px;
    }
    .flex_first {
      flex: 1;
      margin-right: 53px;
    }
    .contract_download_box_special {
      margin-right: 13px;
    }
    .download_button_special {
      margin-top: -10px;
    }
    .upload_demo_box {
      position: relative;
      margin-top: -13px;
    }
    .contract_processing_ul .upload_box {
      margin-left: 13px;
    }
    .contract_processing_ul >>> .el-upload-list__item {
      position: absolute;
      top: -8px;
      left: -1px;
    }
    /* .add_invoice_content{
                                                                                                                                      height: 173px !important;
                                                                                                                                  } */
    .add_invoice_content >>> .el-form-item__label {
      min-width: 106px !important;
    }
    .financing_number >>> .el-form-item__label {
      min-width: 81px !important;
    }
    .special_disabled >>> .el-input.is-disabled .el-input__inner {
      padding-left: 0;
    }
  }
</style>